import React from "react";
import "./styles.scss";

const CardAnnotation = () =>
  (
    <div className="card-annotation">
      <div className="card-annotation-wrapper">
        <p>
          Карта дня Таро на сегодня покажет, что вас ждёт в ближайшие сутки: романтическое знакомство,
          удача, приятный сюрприз или беспокойство? Вы узнаете, к каким радостным событиям
          подготовиться, а, главное, с какими трудностями вы можете столкнуться, и как их предотвратить.
        </p>
        <p>Когда игра поможет вам:</p>
        <ul>
          <li>нервничаете перед важным событием и хотите узнать, как оно пройдёт, чтобы успокоиться</li>
          <li>
            сегодня собираетесь на первое свидание и задумываетесь, действительно ли человек вам
            подходит
          </li>
          <li>
            не можете сделать правильный выбор из нескольких вариантов, не понимаете, на что обратить
            внимание
          </li>
          <li>хотите получить совет на день, чтобы прожить его продуктивно, но в лёгкости</li>
          <li>
            понимаете, что назревает конфликт и хотите понять, как себя вести, чтобы решить вопрос мирно
            и др.
          </li>
        </ul>
        <p>
          Часто каждый из нас интуитивно что-то чувствует внутри, но до конца не может это понять. Игра
          даст вам подсказки, которые помогут по-другому взглянуть на происходящее и увидеть то, что
          скрыто.
        </p>
        <p>
          Но если вдруг у вас вышла, на первый взгляд, тяжёлая карта — не расстраивайтесь и не
          паникуйте. В Таро нет плохих Арканов, каждый из них обращает ваше внимание на то, что вы
          игнорируете или чему сопротивляетесь. А значит это отличный шанс объективно взглянуть на
          свою жизнь и сделать определённые выводы.
        </p>
        <h2>Правила онлайн-игры Таро «Карта дня»</h2>
        <p>
          Лучше делать расклад с утра, чтобы представлять грядущие события и заранее настроиться на
          нужный лад. Также это поможет внимательнее относиться к знакам вокруг себя и не пропустить
          важную информацию. В результате станете поступать более осмысленно и не допустите ошибок.
        </p>
        <ul>
          <li>прикройте глаза и сконцентрируйтесь на себе</li>
          <li>задайте желаемый вопрос на сегодняшний день</li>
          <li>откройте глаза и интуитивно выберите карту</li>
          <li>прочитайте её толкование</li>
        </ul>
        <p>
          Вы можете играть несколько раз подряд, но самые точные значения выпадают только первые два
          раза. Если результат вас не устраивает, а рука так и тянется попробовать сыграть ещё раз, то
          стоит задуматься — не хотите ли вы намеренно получить конкретный ответ? И если да, то какой?
        </p>
        <p>
          В этом случае получить совет на день может быть мало, нужен более подробный разбор ситуации на
          индивидуальной консультации у таролога Lunaro. Чутький эксперт с опытом более 5 лет в Таро
          детально разберёт вашу ситуацию, расскажет про варианты решения проблемы и даст прогноз на
          ближайшее будущее.
        </p>
        <p>
          А ещё вы можете получить бесплатную мини-консультацию, которая продлится 7 минут. За это
          время эксперт по Таро сделает расклад на 3–5 карт и ответит на волнующий вас вопрос.
          Выберите таролога онлайн из каталога и позвоните ему прямо сейчас.
        </p>
      </div>
    </div>
  );

export default CardAnnotation;
