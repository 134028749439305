import React from "react";

import "./styles.scss";
import { CardItemsProps } from "./types";

const CardsList = ({ cards, onClick }: CardItemsProps) =>
  (
    <>
      <div className="cards-heading">
        <img src="/cardOfDay/heading.svg" alt="heading" />
        <div className="cards-heading__description">
          <span>
            Послание дня от колоды Lunaro — отличный способ заглянуть в будущее и настроиться на
            ближайшие 24 часа. Вы узнаете, что хорошее может случиться, или как подготовиться к
            трудностям. Напутствие подарит вам уверенность в собственных силах и поможет решить
            ситуацию в вашу пользу.
          </span>
          <span>
            Закройте глаза, сфокусируйтесь на себе и интуитивно выберите карту.
            Пусть сегодня все ваши планы исполнятся!
          </span>
        </div>
        <div className="cards-heading__disclaimer">
          <h2>Дисклеймер</h2>
          <span>
            Вы можете играть столько раз, сколько захотите. Но достоверные ответы дают только первые
            две карты. Остальные попытки бесполезны с практической стороны, но подталкивают
            задуматься, почему вы ищете конкретный ответ.
          </span>
        </div>
      </div>
      <div className="cards">
        {cards.map((card) =>
          (
            <div
              key={card.id}
              onClick={() =>
                onClick(card)}
              className="card"
            >
              <img src="/cardOfDay/card.svg" alt="card" />
            </div>
          ))}
      </div>
    </>
  );

export default CardsList;
